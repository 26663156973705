<template>
  <v-container
    fill-height
  >
    <v-row>
      <v-col>
        <v-card
        >
          <v-card-title>
            asdf
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home'
}
</script>
